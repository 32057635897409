/**
 * Un écran permettant de configurer l'affichage en fonction de la configuration
 */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { selectLogin } from '../../redux/LoginSlice';
 
 
 
const Screen = ({ redirectScreen, children }) => {
	const value = useSelector(selectLogin);
	
	if(value === '') {
	return (
		<Redirect to={{pathname: redirectScreen}} />
		);
	}

	return (
		<Fragment>
			{children}
		</Fragment>
	);
}

Screen.defaultProps = {
	redirectScreen: '/config',
	children: null
}

export default Screen;