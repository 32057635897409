import React, { Fragment, useState } from 'react';

import TopAppBar, { TopAppBarIcon, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from '@material/react-top-app-bar';
import MenuSurface, { Corner } from '@material/react-menu-surface';
import { MenuListItem, MenuListItemText } from '@material/react-menu';

import Icon from '@mdi/react'
import { mdiDotsVertical, mdiArrowLeft } from '@mdi/js'

import './TopBar.scss';
import '@material/react-top-app-bar/index.scss';
import '@material/react-list/index.scss';
import '@material/react-menu-surface/index.scss';

const TopBar = ({title, options, back, handleBack}) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	let backBtn;
	if(back)
		backBtn = (
			<TopAppBarIcon  actionItem tabIndex={0}>
				<Icon path={mdiArrowLeft} onClick={handleBack} />
			</TopAppBarIcon>
		);
	else
		backBtn = (<Fragment />);

	const topBar = (
		<TopAppBar className='topBar'>
			<TopAppBarRow>
				<TopAppBarSection align='start'>
					{backBtn}
					<TopAppBarTitle>{title}</TopAppBarTitle>
				</TopAppBarSection>
				<TopAppBarSection align='end' role='toolbar'>
					<TopAppBarIcon  actionItem tabIndex={0}>
						<Icon ref={setAnchorEl} path={mdiDotsVertical} onClick={() => setMenuOpen(!menuOpen)}/>
					</TopAppBarIcon>
				</TopAppBarSection>
			</TopAppBarRow>
		</TopAppBar>
	);

	return (
		<Fragment>
			{topBar}
			<MenuSurface
				open={menuOpen}
				onClose={() => setMenuOpen(false)}
				anchorElement={anchorEl}
				anchorCorner={Corner.BOTTOM_LEFT}
				fixed={true}
			>
				{Object.entries(options).map(([key, value], index) => (
					<MenuListItem key={index} onClick={() => {setMenuOpen(false); value()}} >
							<MenuListItemText primaryText={key} />
					</MenuListItem>
				))}
			</MenuSurface>
		</Fragment>
	);
}

TopBar.defaultProps = {
	title: '',
	options: {},
	back: false,
	handleBack: null
}

export default TopBar;