import { Button } from "@material-ui/core";
import { API } from "./config";

/**
 * Définition des niveaux d'erreur
 */
const levels = {
	SUCCESS: 0,
	INFO: 1,
	WARNING: 2,
	ERROR: 3
}


/**
 * Returns a key by its value in the map
 * @param {*} object The map containing the value and key
 * @param {*} value The value to search
 * @returns The first key associated with the value
 */
export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

/**
 * Interprète un niveau de message en chaîne pour le niveau de snackbar
 * 
 * @param {in} level Le niveau du message (0 => 3, success => error)
 * @returns La chaine représentant le niveau
 */
function getVariant(level) {
	switch (level) {
		case levels.SUCCESS:
			return 'success';
		case levels.INFO:
			return 'info';
		case levels.WARNING:
			return 'warning';
		case levels.ERROR:
			return 'error';
		default:
			return 'error';
	}
}

/**
 * Gère l'affichage automatique des messages de snackbar
 * 
 * @param {in} data Les données de retour de l'appel à un WS 
 */
function manageSnackBar(messages, snackbar) {
	
	for (const mess of messages)
	{
		const variant = getVariant(mess.level);

		let action = null;
		let persist = false;

		if(mess.level === levels.ERROR) {
			action = key => (
				<Button onClick={() => snackbar.closeSnackbar(key)}>OK</Button>
			)
			persist = true;
		}

		snackbar.enqueueSnackbar(mess.message, {
			variant: variant,
			action,
			persist: persist,
			autoHideDuration: 3000
		});
	}
	
}

/**
 * Routine permettant de retourner une réponse générique en cas de mode hors ligne
 */
function getOfflineData(error) {

	if(error) {
		return {
			status: 'SUCCESS',
			errors: [
				{
					code: -1,
					message: 'Erreur : pas de connexion Internet',
					level: levels.ERROR
				}
			]
		}
	}
	else {
		return {
			status: 'SUCCESS',
			errors: [
				{
					code: -1,
					message: 'Envoi différé : pas de connexion Internet',
					level: levels.INFO
				}
			]
		}
	}
	
}

/**
 * Une version avec timeout de fetch
 * @param {in} resource La ressource à fetch
 * @param {in} options Les options du fetch
 * @returns 
 */
async function fetchWithTimeout(resource, options = {}) {
	const { timeout = 8000 } = options;
	
	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);
  
	const response = await fetch(resource, {
	  ...options,
	  signal: controller.signal  
	});
	clearTimeout(id);
  
	return response;
  }


/**
 * Calls an API endpoint with some data
 * @param {in} method Le type de requête (POST, GET, PUT, ...)
 * @param {in} endpoint The endpoint (format: /<cat>/<endpoint>)
 * @param {in} data Les données de requête
 * @param {in} token Le token utilisateur, ou null
 * @param {in} snackbar La snackbar pour la gestion des messages
 * @param {in} historize true si la requête doit être historisée pour être jouée plus tard en cas d'erreur, false sinon
 */
export function fetchAPI(method, endpoint, data, token, snackbar, historize) {
    const url = process.env.REACT_APP_API + endpoint;

	if(historize) {
		data.date = Date.now();
	}

	return fetchWithTimeout(url, {
		method: method,
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			'data': data,
			'token': token
		})
	}).then(response => response.json()).then(data => {
		manageSnackBar(data['errors'], snackbar);
		return data;
	}).catch(() => {
		if(historize) {
			if ('serviceWorker' in navigator && 'SyncManager' in window) {
				return Promise.resolve(getOfflineData(false)).then(data => {
					manageSnackBar(data.errors, snackbar);
					return data;
				});
			}
		}

		return Promise.resolve(getOfflineData(true)).then(data => {
			manageSnackBar(data.errors, snackbar);
			return data;
		});
	});
	
}
