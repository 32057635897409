import { Box } from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter, Switch, useHistory } from 'react-router-dom';
import './App.css';
import Login from './components/Login/Login';
import { selectLogin } from './redux/LoginSlice';
import MainMenu from './components/MainMenu/MainMenu';
import QRReader from './components/QRReader/QRReader';
import Screen from './components/Screen/Screen';
import TopBar from './components/TopBar/TopBar';
import { fetchAPI } from './util/util';

const AppWrapper = () => {
	return (
		<BrowserRouter>
			<SnackbarProvider maxSnack={3}>
				<App /> 
			</SnackbarProvider>
		</BrowserRouter>
	)
  }

const App = () => {
	const history = useHistory();
	const snackbar = useSnackbar();
	
	const handleQR = useCallback(() => history.push('/qr'), [history]);
	const handleConfig = useCallback(() => history.push('/config'), [history]);
	const handleRoot = useCallback(() => history.push('/'), [history]);
	const handleBack = useCallback(() => history.goBack(), [history]);

	const value = useSelector(selectLogin);
	const submitQR = (id) => {
		const data = {
			'locId': id,
			'name': value
		};

		fetchAPI('PUT', '/records/add', data, null, snackbar, true).then(data => {
			if(data.status === 'SUCCESS') /* On ne revient en arrière que s'il n'y a pas d'erreur */
				handleBack();
		});
		
	};



	const options = {
		'Paramétrage': handleConfig, 
	};

	return (
		<Switch>
			<Route path='/qr'>
				<Screen redirectScreen='/config'>
					<TopBar title="Déclarer ma présence" options={options} back={true} handleBack={handleBack} />
					<Box className='screen'>
						<QRReader handleQR={submitQR} />
					</Box>
				</Screen>
			</Route>
			<Route path='/config'>
				<TopBar title="Paramétrage" options={options} back={true} handleBack={handleBack} />
				<Box className='screen'>
					<Login handleRoot={handleRoot} />
				</Box>
			</Route>
			<Route path='/'>
				<Screen redirectScreen='/config'>
					<TopBar title="Les lions du 8" options={options} back={false} />		
					<Box className='screen'>
						<MainMenu handleQR={handleQR} />
					</Box>
				</Screen>	
			</Route> 
		</Switch>
	);
}

export default AppWrapper;
