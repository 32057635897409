import { combineReducers, configureStore } from '@reduxjs/toolkit'
import loginReducer from './LoginSlice';
import persistStore from 'redux-persist/es/persistStore';
import { persist } from './persist';

const persistConfig = {
	key: 'lions8'
}

const combinedReducers = combineReducers({
	login: loginReducer
})

const persistedReducer = persist(persistConfig, combinedReducers);

/* Redux store : inter-elements storage */
const store = configureStore({  
	reducer: persistedReducer,
});

export default store;

/* Persistence layer */
export const persistor = persistStore(store);
