import { Button, Card, CardContent } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import QrReader from 'react-qr-reader';

const QRReader = ({ handleQR }) => {
 
	const [data, setData] = useState(null);
	const [ID, setID] = useState(-1);

	const handleScan = data => {
		if(data) {
			let obj = JSON.parse(data);
			setData(obj.name);
			setID(obj.id);
		}
	}

	const handleError = err => {
		console.error(err)
	}

	let btn;

	if(data) {
		btn = (<Button variant="contained" color="primary" onClick={() => handleQR(ID)}>
				Présent sur {data}
			</Button>
		);
	}
	else {
		btn = (<Button variant="contained" color="primary" disabled>
				Scannez un code QR
			</Button>
		);
	}

	 return (
		<Fragment>
			<Card className='card'>
				<CardContent>
					<QrReader 
						delay={500}
						onScan={handleScan}
						showViewFinder={true}
						facingMode='environment'
						resolution={600}
						className='card'
						onError={handleError}
					/>
				</CardContent>
			</Card>
			{btn}
		</Fragment>
	 );
}

QRReader.defaultProps = {
	handleQR: null
}

export default QRReader;