import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({  
	name: 'login',  
	initialState: {    
		value: '',  
	},  
	reducers: {    
		login: (state, action) => {
			state.value = action.payload
		}
			
	}
});


export const { login } = loginSlice.actions;

export const selectLogin = state => state.login.value;

export default loginSlice.reducer;