/**
 * A basic 'Login'
 */
import { Button, Card, CardContent, TextField } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, selectLogin } from '../../redux/LoginSlice';


const Login = ({ handleRoot }) => {
	const value = useSelector(selectLogin);
	const dispatch = useDispatch();

	return (
		<Fragment>
			<Card className='card'>
				<CardContent>
					<TextField 
						label='Nom Prénom'
						value={value}
						onChange={e => dispatch(login(e.target.value))}
						placeholder='Nom Prénom'
						fullWidth={true}
					/>

				</CardContent>
			</Card>
			
			<Button onClick={handleRoot} variant="contained" color="primary">
				Enregistrer
			</Button>
		</Fragment>
	);
}

Login.defaultProps = {
	handleRoot: null
}

export default Login;